#services{
    width: 100vw;
}

#service-section{
    margin-top: 50px;
    color: #001639;
}

#service-section .title-div {
    text-align: center;
    margin-bottom: 40px;
}

#service-section .title-div h6{
    margin-bottom: 15px;
}

#service-section .title-div .paragraph{
    display: none;
}

#service-section .card-details{
    padding: 35px 0;
}

.service-image-div{
    content: '';
    height: 28vh;
    width: 100%;
    border-radius: 4px 4px 0 0;
}

.consultation-image{
    background-image: url(./images/consultation-image.jpg);
    background-size: cover;
}

.training-image{
    background-image: url(./images/training-image.jpg);
    background-size: cover;
}

.card-details .service-card-list{
    margin-block-start: 0;
    padding: 0;
    list-style-position: inside;
}

.card-details .service-card-list li {
    color: #d8973c;
    margin-bottom: 10px;
    font-weight: 400;
    letter-spacing: 1px;
}

.card-details .service-card-list li span {
    color: #001639;
    margin-left: -9px;
}


