@import url(https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500;600;700;800&family=Open+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600;700&display=swap);
.navbar{
    position: fixed;
    top: 0;
    padding: 20px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    color: #001639;
    background-color: #f9f9f9;
    box-shadow: 0px 1px 3px rgba(72, 72, 72, 25%);
    z-index: 50;
}


.navbar-logo-link{
    text-decoration: none;
    color: inherit;
}

.navbar .navlinks a{
    color: #001639;
    text-decoration: none;
    margin: auto 7px;
    padding: 2px 1px;
    font-weight: 600;
    letter-spacing: 1px;
}

.navbar .navlinks a:hover{
    color: #d8973c;
}

.navbar .navlinks a.active{
    border-bottom: #d8973c 2px solid;
    color: #001639;
}

.navbar .navlinks a.active:hover{
    color: #001639;
    cursor: default;
}

.navbar .navlinks a:last-of-type{
    margin-right: 0;
}

.navbar .nav-contact{
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 25%;
}

.navbar .nav-contact span{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}


.nav-menu{
    width: 100%;
    color: #001639;
}
#title{
    text-align: center;
    margin-bottom: 5px;
}
#intro{
    background-image: url(/static/media/home-slide-1.8a91074d.jpg);
    background-position-y: 30px;
    background-color: #3f89ff;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 30px;
}

.intro-background{
    content: '';
    width: 100%;
    top: 0;
    left: 0;
    background-color: #001639ad;
    z-index: 0;
}

/* Services Section */
#services-section{
    margin-top: 40px;
}

#services-info-section {
    text-align: center;
    margin-bottom: 35px;
}

#services-info-section .h6 {
    text-align: center;
    margin-top: 10px;
    margin: 5px 0 15px;
}


#services-list a {
    text-decoration: none;
}

.consult-service-card{
    background-image: url(/static/media/consult-image.aeec7038.jpg);
    background-size: cover;
    border-radius: 15px;
}

.direct-rep-service-card{
    background-image: url(/static/media/direct-rep-image.7fa9353a.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 50%;
    border-radius: 15px;
}

.training-service-card{
    background-image: url(/static/media/training-image.20472556.jpg);
    background-size: cover;
    border-radius: 15px;
}

.mentor-service-card{
    background-image: url(/static/media/mentor-image.37e16878.jpg);
    background-size: cover;
    border-radius: 15px;
}

.service-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-color: rgb(0, 22, 57, 0.6);
    color: #f9f9f9;
    height: 230px;
    padding: 0 25px;
    border-radius: 15px;
    margin-bottom: 20px;
}

.consult-service-card h3{
    letter-spacing: 3px;
}

.consult-service-card:hover,
.direct-rep-service-card:hover,
.training-service-card:hover,
.mentor-service-card:hover{
    transform: scale(1.03);
    transition: .2s;
}

.service-card:hover{
    background-color: rgb(0, 22, 57, 0.85);
}

.direct-rep-service-card .service-card .h3{
    letter-spacing: 0px;
    margin-top: 9px;
}

.training-service-card .service-card .h3,
.mentor-service-card .service-card .h3{
    letter-spacing: 2px;
}

.service-card span{
    text-align: right;
    position: relative;
    top: 15%;
    font-size: 16px;
    letter-spacing: 3px;
    font-family: 'Noto Sans TC', sans-serif;
    transition: .2s;
}

.service-card:hover span{
    opacity: 1;
    transition: .2s;
}

#services-info-card{
    display: none;
    flex-direction: column;
    justify-content: center;
    background-color: #001639;
    min-height: 230px;
    padding: 0 75px;
    color: #f9f9f9;
    border-radius: 15px;
    box-shadow: inset 0 0 0px 3px #d8973c;
}

#services-info-card .h3{
    color: #d8973c;
    text-align: center;
    margin-bottom: 10px;
}

#services-info-card p{
    text-align: center;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 20px;
}

#services-info-card:hover{
    cursor: default;
}

#home #about-content{
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #ffffff;
}

.header-container{
    background-image: url(/static/media/pillers.07317a3c.jpg);
    margin-top: 70px;
    background-repeat: no-repeat;
    background-size: cover;
}

.header{
    padding: 90px 0;
    text-align: center;
    background-color: rgba(0, 22, 57, 85%);    
    color: #ffffff;
}

.header h1{
    font-size: 3rem;
    letter-spacing: 2px;
    text-shadow: 2px 2px 8px #001639;
    text-transform: uppercase;
}

#about #about-content{
    background-color: #ffffff;
    padding-top: 25px;
    padding-bottom: 30px;
}

#about-content .jess-image-div{
    margin: auto;
    position: relative;
    top: 15px;
}

#about-content .jess-image-div #title{
    margin-bottom: -10px;
}


#about-content .jess-image-div h1{
    text-align: left;
}

#about-content .jess-image-div img{
    width: 100%;
    max-width: 350px;
    transform: scaleX(-1);
}

#about-content .jess-image-div h1{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-bottom: #d8973c 3px solid;
    padding-right: 10px;
    margin: auto;
    font-weight: 500;
}

#about-content .about-p-div{
    margin-top: -10px;
}

#about-content .about-p-div p{
    margin-bottom: 20px;
    line-height: 25px;
    color: #7d7d7d;
    font-weight: 500;
}

#services{
    width: 100vw;
}

#service-section{
    margin-top: 50px;
    color: #001639;
}

#service-section .title-div {
    text-align: center;
    margin-bottom: 40px;
}

#service-section .title-div h6{
    margin-bottom: 15px;
}

#service-section .title-div .paragraph{
    display: none;
}

#service-section .card-details{
    padding: 35px 0;
}

.service-image-div{
    content: '';
    height: 28vh;
    width: 100%;
    border-radius: 4px 4px 0 0;
}

.consultation-image{
    background-image: url(/static/media/consultation-image.aeec7038.jpg);
    background-size: cover;
}

.training-image{
    background-image: url(/static/media/training-image.20472556.jpg);
    background-size: cover;
}

.card-details .service-card-list{
    -webkit-margin-before: 0;
            margin-block-start: 0;
    padding: 0;
    list-style-position: inside;
}

.card-details .service-card-list li {
    color: #d8973c;
    margin-bottom: 10px;
    font-weight: 400;
    letter-spacing: 1px;
}

.card-details .service-card-list li span {
    color: #001639;
    margin-left: -9px;
}



#contactContent{
    padding-bottom: 60px;
    margin: 50px auto 0;
}

#contactContent .contact-greeting-div{
    text-align: center;
    margin-bottom: 45px;
}

#contactContent .contact-greeting-div #title{
    text-align: center;
    margin-bottom: 5px;
}

#contactContent .contact-greeting-div h6{
    margin-bottom: 10px;
    text-transform: lowercase;
}

/* .contact-submit-btn-div{
    width: 50%;
    margin: auto;
} */
#footer{
    padding: 25px 0;
    background-color: #001639;
    width: 100vw;
    color: #f9f9f9;
    text-align: center;
}

#footer h3{
    font-weight: 500;
}

.app{
  overflow-x: hidden;
  background-color: #f9f9f9;
  color: #001639;
  font-family: "Open Sans", sans-serif;
}

.h1{
  text-transform: capitalize;
  margin: 10px auto 12px;
  font-family: 'EB Garamond', serif;
  font-weight: 700;
  line-height: 45px;
  font-size: 48px;
}

.h2{
  margin: 0;
  text-transform: capitalize;
  font-family: 'EB Garamond', serif;
  line-height: 40px;
  font-size: 42px;
  font-weight: 700;
  letter-spacing: 1px;
}

.h3{
  margin: 0;
  text-transform: capitalize;
  font-family: 'EB Garamond', serif;
  font-size: 33px;
  line-height: 40px;
  font-weight: 500;
  letter-spacing: 2px;
}

.h4{
  margin: 0;
  text-transform: capitalize;
  font-size: 33px;
  line-height: 40px;
  font-weight: 500;
  letter-spacing: 1px;
}

.h6{
  margin: 0;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #7d7d7d;
}

p{
  margin: 0;
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.no-ul{
  list-style: none;
  padding: 0;
}

.no-a{
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  /* Home */
  #intro{
    background-position-x: 75%;
  }

  #jess-img, #counting-numbers {
      box-shadow: none;
  }


}

@media only screen and (min-width: 600px) {
  /* Navbar */
  .navbar{
    padding: 10px 0px;
  }

  .navbar-logo-link{
    width: 25%;
  }

  /* Header Component */
  .header-container {
    background-position-y: bottom;
    margin-top: 60px;
}

  .header {
    padding: 125px 0;
  }

  .header h1 {
    font-size: 4rem;
    letter-spacing: 1px;
  }

  /* Home Page*/
  #intro{
    background-position-y: 0;
  }

  .intro-background{
    background: linear-gradient(90deg, rgba(0,22,57,100%) 15%, rgba(0,22,57, 40%) 78%);
  }

  .intro-title{
    text-align: left;
  }

  #title{
    text-align: left;
    margin-bottom: 30px;
  }

  #services-section {
    margin-top: 50px;
  }

  .service-card{
    margin-bottom: 0px;
  }

  .service-card span {
    opacity: 0;
  }

  #services-info-section{
    display: none;
  }

  #services-info-card{
    display: flex;
  }

  #home #about-content {
    padding-top: 60px;
    margin-top: 45px;
    padding-bottom: 0px;
  }

  #home #about-content .jess-image-div #title {
    text-align: left;
  }

  /* About Page*/
  #about #about-content{
    background-color: #ffffff;
    padding-top: 90px;
    padding-bottom: 0;
  }

  #about-content .jess-image-div{
    top: -25px;
  }

  #about-content .jess-image-div h1 {
    font-weight: 400;
  }

  #about-content .jess-image-div #title{
    margin-bottom: -20px;
  }

  #about-content .about-p-div{
    margin-top: -0;
  }

  /* Services Page */
  #service-section .title-div {
    text-align: center;
    max-width: 60%;
    margin: 0 auto 50px;
  }

  #service-section .title-div #title{
    text-align: center;
  }

  #service-section .title-div .summary{
    display: none;
  }

  #service-section .title-div .paragraph{
    display: block;
  }

  .service-image-div {
    height: 100%;
    border-radius: 4px 0 0 4px;
  }

  #service-section .card-details {
    padding: 30px 15px;
  }

  .card-details .service-card-list li {
    letter-spacing: 0;
  }

  /* Contact */
  #contactContent {
    max-width: 60%;
  }

  .contact-submit-btn-div {
    width: 50%;
    margin: auto;
}

}


