#intro{
    background-image: url('../../images/home-slide-1.jpg');
    background-position-y: 30px;
    background-color: #3f89ff;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 30px;
}

.intro-background{
    content: '';
    width: 100%;
    top: 0;
    left: 0;
    background-color: #001639ad;
    z-index: 0;
}

/* Services Section */
#services-section{
    margin-top: 40px;
}

#services-info-section {
    text-align: center;
    margin-bottom: 35px;
}

#services-info-section .h6 {
    text-align: center;
    margin-top: 10px;
    margin: 5px 0 15px;
}


#services-list a {
    text-decoration: none;
}

.consult-service-card{
    background-image: url(./images/consult-image.jpg);
    background-size: cover;
    border-radius: 15px;
}

.direct-rep-service-card{
    background-image: url(./images/direct-rep-image.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 50%;
    border-radius: 15px;
}

.training-service-card{
    background-image: url(./images/training-image.jpg);
    background-size: cover;
    border-radius: 15px;
}

.mentor-service-card{
    background-image: url(./images/mentor-image.jpg);
    background-size: cover;
    border-radius: 15px;
}

.service-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-color: rgb(0, 22, 57, 0.6);
    color: #f9f9f9;
    height: 230px;
    padding: 0 25px;
    border-radius: 15px;
    margin-bottom: 20px;
}

.consult-service-card h3{
    letter-spacing: 3px;
}

.consult-service-card:hover,
.direct-rep-service-card:hover,
.training-service-card:hover,
.mentor-service-card:hover{
    transform: scale(1.03);
    transition: .2s;
}

.service-card:hover{
    background-color: rgb(0, 22, 57, 0.85);
}

.direct-rep-service-card .service-card .h3{
    letter-spacing: 0px;
    margin-top: 9px;
}

.training-service-card .service-card .h3,
.mentor-service-card .service-card .h3{
    letter-spacing: 2px;
}

.service-card span{
    text-align: right;
    position: relative;
    top: 15%;
    font-size: 16px;
    letter-spacing: 3px;
    font-family: 'Noto Sans TC', sans-serif;
    transition: .2s;
}

.service-card:hover span{
    opacity: 1;
    transition: .2s;
}

#services-info-card{
    display: none;
    flex-direction: column;
    justify-content: center;
    background-color: #001639;
    min-height: 230px;
    padding: 0 75px;
    color: #f9f9f9;
    border-radius: 15px;
    box-shadow: inset 0 0 0px 3px #d8973c;
}

#services-info-card .h3{
    color: #d8973c;
    text-align: center;
    margin-bottom: 10px;
}

#services-info-card p{
    text-align: center;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 20px;
}

#services-info-card:hover{
    cursor: default;
}

#home #about-content{
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #ffffff;
}
