.header-container{
    background-image: url(../../images/pillers.jpg);
    margin-top: 70px;
    background-repeat: no-repeat;
    background-size: cover;
}

.header{
    padding: 90px 0;
    text-align: center;
    background-color: rgba(0, 22, 57, 85%);    
    color: #ffffff;
}

.header h1{
    font-size: 3rem;
    letter-spacing: 2px;
    text-shadow: 2px 2px 8px #001639;
    text-transform: uppercase;
}
